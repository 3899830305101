@font-face {
  font-family: 'RoadRage';
  src: url(./fonts/Road_Rage.otf) format('opentype');
}
html {padding: 0; margin: 0;}
body { 
  margin: 0; 
  color: #fff;
  padding: 0;
}
body:before {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  // background: linear-gradient(0deg, rgba(0,0,0,1) 25%, rgba(126,1,128,0.8267682072829132) 50%, rgba(0,0,0,1) 75%);
  z-index: -1;
  transition: background-color 0.3s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#root {
  width: 100%;
  height: 100%;
  position: fixed;
}
canvas { 
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  animation: 2s cubic-bezier(0.075, 0.82, 0.165, 1) 1s forwards fadeIn;
}
section { 
  color: #fff;
  max-width: 900px;
  margin: 0 auto 2rem;
}
img {
  max-width: 100%;
}
h1 {
  font-family: 'RoadRage';
  font-weight: normal;
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

h2 {
  margin-top: 0;
}
.overlay {
  position: relative;
  display: flex;
  height: 100%;
  z-index: 10;

  h1 {
    opacity: 1;
    visibility: visible;
    font-size: 7vw;
    color: #fff;
    letter-spacing: -1vw;
    text-align: center;
    position: relative;
    transform: scale(0);
    width: 100%;
    margin: auto;
    animation: 1.3s cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s forwards zoomIn;
    transition: opacity 0.6s ease, visibility 0.6s ease;

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
    
    @keyframes glow {
      0% {
        text-shadow: 0 0 10px #fff;
      }
      100% {
        text-shadow: 10px 10px #6b0139;
      }
    }

    span {
      position: relative;
      animation: 1s cubic-bezier(0.075, 0.82, 0.165, 1) 3s forwards glow;
      margin: 0 0.5rem;

      &:nth-child(1) {
        animation-delay: 2s;
      }
      &:nth-child(2) {
        animation-delay: 2.1s;
      }
      &:nth-child(3) {
        animation-delay: 2.2s;
      }
      &:nth-child(4) {
        animation-delay: 2.3s;
      }
      &:nth-child(5) {
        animation-delay: 2.4s;
      }
      &:nth-child(6) {
        animation-delay: 2.5s;
      }
      &:nth-child(7) {
        animation-delay: 2.6s;
      }
      &:nth-child(8) {
        animation-delay: 2.7s;
      }
      &:nth-child(9) {
        animation-delay: 2.8s;
      }
      &:nth-child(10) {
        animation-delay: 2.9s;
      }
    }
  }
}

.button-group {
  position: absolute;
  height: 4rem;
  display: flex;
  justify-content: center;
  top: 70vh;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes expand {
  0% {
    height: 0;
    width: 0;
    border-width: 2px;
  }
  50% {
    width: 0;
    height: 3rem;
    border-width: 2px;
  }
  100% {
    width: 10rem;
    height: 3rem;
    border-width: 2px;
  }
}

.button {
  position: relative;
  align-self: center;
  background: transparent;
  transform: skew(-30deg);
  outline: 0;
  width: 10rem;
  height: 3rem;
  border: 2px solid rgb(187, 245, 255);
  color: rgb(187, 245, 255);
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.6s ease, visibility 0.6s ease;

  &:before {
    content: '';
    width: 100%;
    height: 0;
    background-color: #000;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: height 0.1s cubic-bezier(0.03, 0.49, 0.38, 1.01);
    z-index: -1;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    &:before {
      height: 100%;
    }
  }

  span {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    align-self: center;
    transform: skew(30deg);
    z-index: 10;
  }

  &-drive {
    border-width: 0px;
    width: 0;
    height: 0;
    animation: 1s cubic-bezier(0.075, 0.82, 0.165, 1) 2.5s forwards expand;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  
    span {
      opacity: 0;
      animation: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 2.5s forwards fadeIn;
    }
  }
}

.dashboard {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 770px) {
    transform: scale(0.45);
  }

  .odometer {
    display: inline-flex;
    align-self: flex-end;
    margin-bottom: 17vh;

    .dials {
      display: flex;
      justify-content: center;
      position: relative;
      width: 10rem;
      height: 10rem;
      border-radius: 100%;
      border: 1px solid #aaa;
      margin: 0 8rem;

      .text {
        position: relative;
        align-self: flex-end;
        bottom: -2rem;
        font-weight: bold;
        font-size: 0.9rem;
      }
      
      ul {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        counter-reset: section;

        li {
          position: absolute;
          top: 50%;
          left: 50%;
          list-style-type: none;
          border-radius: 100%;
  
          &:before {
            content: '';
            position: absolute;
            top: 9.2rem;
            background-color: #fff;
            width: 4px;
            height: 1.2rem;
          }

          span.number {
            position: absolute;
            top: 7.5rem;
            left: -0.5rem;
          }
        }
      }

      &.mph {
        ul {
          li {
            &:nth-child(odd) {
              &:before {
                height: 0.7rem;
                top: 9.7rem;
                width: 1px;
              }
            }
          }
        }
      }
    }

    .needle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: rotate(50deg);

      &:before {
        content: '';
        position: absolute;
        width: 4px;
        height: 9rem;
        left: -2px;
        background-color: white;
      }
    }
    
  }
}

.keyboard-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  top: 50%;
  left: 50%;
  background-color: #001a38f2;
  margin: 0;
  width: 50vw;
  height: 60vh;
  transform: translate(-50%,-50%);
  transition: opacity 0.3s ease, visibility 0.3s ease;

  &:before {
    content: '';
    position: absolute;
    top: -1rem;
    left: -1rem;
    width: calc(100% + 1.4rem);
    height: calc(100% + 1.4rem);
    background-color: transparent;
    border: 5px solid #031b38;
  }

  h2 {
    font-size: 2.2rem;
    margin: 0;
    align-self: center;
    text-align: center;
    line-height: 1.4;
    text-shadow: 0 0 5px #fff;
    font-family: Arial, Helvetica, sans-serif;

    span {
      font-size: 0.7em;
    }
  }

  .content {
    align-self: center;
    height: 14rem;
    width: 100%;
    display: flex;
    justify-content: center;

    .container {
      position: relative;
      align-self: center;

      button {
        position: absolute;
        left: -1rem;
        margin: 0;
        width: 3rem;
        height: 3rem;
        background-color: #b327aa;
        color: #fff;
        outline: 0;
        border: 0;
        border-radius: 0.5rem;
        user-select: none;
        -webkit-user-select: none;
        transition: 
          background-color 0.2s ease,
          transform 0.2s ease;
    
        &.active {
          background-color: #92198a;
          transform: scale(0.95);
        }
        &:after {
          pointer-events: none;
          content: attr(title);
          position: absolute;
          color: #cacaca;
        }
        &.forward {
          top: -4rem;
        }
        &.forward:after {
          top: -1.5rem;
          left: 0;
        }
        &.reverse {
          top: 0;
        }
        &.reverse:after {
          top: 3.5rem;
          left: 0;
        }
        &.left {
          left: -5rem;
        }
        &.left:after {
          top: 3.5rem;
          left: 0;
        }
        &.right {
          left: 3rem;
        }
        &.right:after {
          top: 3.5rem;
          left: 0;
        }
      }
    }

  }

  button {
    align-self: center;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

 
  
}

.joystick-overlay {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: rgba(255,255,255,0.2);
  position: fixed;
  bottom: 10vh;
  left: calc(50vw - 50px);
  z-index: 10000;

  .joystick {
    width: 0;
    height: 0;
    overflow: visible;
    position: relative;

    &:before {
      content: '';
      width: 50px;
      height: 50px;
      position: absolute;
      left: -25px;
      top: -25px;
      background-color: blue;
      border-radius: 25px;
      pointer-events: none;
      position: absolute;
    }
  }
}

.radio {
  padding: 0;
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%);
  background: none;
  border: 0;
  outline: 0;
  color: #fff;
  font-family: monospace;
  z-index: 10;

  .dials {
    background: none;
    outline: 0;
    border: 2px solid #fff;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    display: inline-block;
    line-height: 0;
    position: absolute;
    cursor: pointer;
  }
  .volume {
    left: -3rem;
  }
  .tuner {
    right: -3rem;
  }
  button[data-track] {
    background: none;
    border: 0;
    outline: 0;
    color: #fff;
    cursor: pointer;
    transition: text-shadow 0.4s ease;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -0.8rem;
    left: 20%;
    height: 35px;
    width: 2px;
    background-color: orange;
    z-index: -1;
    transition: left 0.4s ease-in-out;
  }
  &[data-current-track]:after { left: 0; }
  &[data-current-track="1"]:after { left: 8%; }
  &[data-current-track="2"]:after { left: 33%; }
  &[data-current-track="3"]:after { left: 57%; }
  &[data-current-track="4"]:after { left: 85%; }
  &[data-current-track="1"] button[data-track="1"],
  &[data-current-track="2"] button[data-track="2"],
  &[data-current-track="3"] button[data-track="3"],
  &[data-current-track="4"] button[data-track="4"] { 
    text-shadow: 0 0 5px #fff; 
  }
  
  &:before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #fff;
  }
}
